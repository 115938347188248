export default {
  namespace: true,
  state: {
    titleShow: true,
    titleValue: '',
    titleLeft: true,

    stepsShow: true,
    stepsValue: [],
    stepsActive: 0,
  },
  mutations: {
    setTitleShow(state, payload) {
      state.titleShow = payload;
    },
    setTitleValue(state, payload) {
      state.titleValue = payload;
      state.titleShow = true;
      const notShowArray = ['选择账户', '蜂投证券开户/查询', '查询', '提交完成', 'ISDA协议'];
      if (notShowArray.indexOf(payload) === -1) {
        state.titleLeft = true;
      } else {
        state.titleLeft = false;
      }
    },
    setTitleLeft(state, payload) {
      state.titleLeft = payload;
    },
    setStepsShow(state, payload) {
      state.stepsShow = payload;
    },
    setStepsValue(state, payload) {
      state.stepsValue = payload;
      state.stepsShow = true;
    },
    setStepsActive(state, payload) {
      state.stepsActive = payload;
      state.stepsShow = true;
    },
  },
  actions: {
    // 执行异步请求
  },
};
