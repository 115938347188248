import CookieSet from './cookie';
/** 验证金额: 只能数字、最多12位 */
const format_fund = (value) => value.replace(/[^0-9]+/g, '').substr(0, 12);
/** 验证验证码: 只能数字、最多6位 */
const format_ver_code = (value) => value.replace(/[^0-9]+/g, '').substr(0, 4);
/** 验证邮件: (字母数字_-)中文@(字母数字_-).(字母数字_-)... */
const format_email = (value) => value.replace(/^[A-Za-z0-9_=\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/g, '').substr(0, 6);
/** 验证电话 */
const format_mobile = (value) => value.replace(/[^0-9]+/g, '').substr(0, 12);
/** 验证办公电话: 数字 字母 — + */
// const format_email = (value) => value.replace(//g, '').substr(0, 6);
/** cookie和跳转时间 */
const setCookieValue = (text, expires) => {
  CookieSet.set('phone', text, { expires: new Date(Number(expires)) });
};
/**
 * https://kh.beevestwm.com 开户ISDA线上域名
 * https://kh.beevestsec.com 开户线上域名
 */
const getIsKH = () => {
  if (document.location.hostname.startsWith('kh.beevestsec')
    || document.location.hostname.startsWith('testcaweb')) {
    return true;
  }
  return false;
};
const getIsISDA = () => {
  if (document.location.hostname.startsWith('kh.beevestwm')
    || document.location.hostname.startsWith('testisdaweb')) {
    return true;
  }
  return false;
};

// 在开户页面获取isda域名，从开户跳转而来
const getIsdaHostInKH = () => {
  // host 为开户域名
  const { host = '' } = document.location;
  // 获取子域名
  const firstDomain = host.split('.')[0];
  // 如果是test环境 testcaweb.investmaster.cn
  if (firstDomain.startsWith('test')) {
    // TODO 新环境测试 后缀hk testcawebhk.investmaster.cn
    if (firstDomain.endsWith('hk')) {
      return 'https://testisdawebhk.investmaster.cn';
    }
    return 'https://testisdaweb.investmaster.cn';
  }
  return 'https://kh.beevestwm.com';
};

// 获取开户域名
const getCaHost = () => {
  const { host = '' } = document.location;
  // 获取子域名
  const firstDomain = host.split('.')[0];
  // 如果是test环境
  // https://testcaweb.investmaster.cn 或者 https://testisdaweb.investmaster.cn
  if (firstDomain.startsWith('test')) {
    // TODO 新环境测试 后缀hk
    // https://testcawebhk.investmaster.cn 或者 https://testisdawebhk.investmaster.cn
    if (firstDomain.endsWith('hk')) {
      return 'https://testcawebhk.investmaster.cn';
    }
    return 'https://testcaweb.investmaster.cn';
  }
  return 'https://kh.beevestsec.com';
};
const callApp = (call) => {
  const url = `forexmaster://fdt/${call}`;
  const iframe = document.createElement('iframe');
  iframe.style.width = '1px';
  iframe.style.height = '1px';
  iframe.style.display = 'none';
  iframe.src = url;

  if (document.body) {
    document.body.appendChild(iframe);
    setTimeout(() => {
      iframe.parentNode.removeChild(iframe);
    }, 100);
  } else {
    window.onload = () => {
      document.body.appendChild(iframe);
      setTimeout(() => {
        iframe.parentNode.removeChild(iframe);
      }, 100);
    };
  }
};
const setPdfName = (name) => name.replace(/\.pdf$/, `${new Date().valueOf()}bvt.pdf`);
const formatPdfName = (name) => name.replace(/\d{13}bvt.pdf$/, '.pdf');
export default {
  format_fund,
  format_ver_code,
  format_email,
  format_mobile,
  setCookieValue,
  getIsdaHostInKH,
  getCaHost,
  getIsKH,
  getIsISDA,
  callApp,
  setPdfName,
  formatPdfName,
};
